<template>
  <!--begin::Menu wrapper-->
  <div
    v-if="headerMenuDisplay"
    class="app-header-menu app-header-mobile-drawer align-items-stretch"
    data-kt-drawer="true"
    data-kt-drawer-name="app-header-menu"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="225px"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_app_header_menu_toggle"
    data-kt-swapper="true"
    data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
    data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
  >
    <!--begin::Menu-->
    <div
      id="kt_app_header_menu"
      class="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
      data-kt-menu="true"
    >
<!--      <LMenuPages></LMenuPages>-->
    </div>
    <!--end::Menu-->
  </div>
  <div v-else class="align-items-stretch"></div>
  <!--end::Menu wrapper-->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getAssetPath } from '@/assets/theme/metronic/ts/helpers/assets';
import LMenuPages from '@/components/theme/metronic/layout/header/menu/MenuPages.vue';
import { headerMenuDisplay } from '@/assets/theme/metronic/ts/helpers/config';

export default defineComponent({
  name: 'ThemeHeaderMenu',
  components: {
    LMenuPages
  },
  setup() {
    return {
      headerMenuDisplay,
      getAssetPath
    };
  }
});
</script>
