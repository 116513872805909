<template>
  <!--begin::sidebar-->
  <div
    v-if="displaySidebar"
    id="kt_app_sidebar"
    ref="sidebarRef"
    class="app-sidebar flex-column"
    data-kt-drawer="true"
    data-kt-drawer-name="app-sidebar"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="225px"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
  >
    <LSidebarLogo :sidebar-ref="sidebarRef"></LSidebarLogo>
    <LSidebarMenu></LSidebarMenu>
<!--    <LSidebarFooter></LSidebarFooter>-->
  </div>
  <!--end::sidebar-->
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import { displaySidebar } from '@/assets/theme/metronic/ts/helpers/config';
import LSidebarLogo from '@/components/theme/metronic/layout/sidebar/SidebarLogo';
import LSidebarMenu from '@/components/theme/metronic/layout/sidebar/SidebarMenu';
import LSidebarFooter from '@/components/theme/metronic/layout/sidebar/SidebarFooter';

export default defineComponent({
  name: 'ThemeSidebar',
  components: {
    LSidebarLogo,
    LSidebarMenu,
    LSidebarFooter
  },
  setup() {
    const sidebarRef = ref<HTMLFormElement | null>(null);

    return {
      displaySidebar,
      sidebarRef
    };
  }
});
</script>
