<template>
  <!--begin::Footer-->
  <div id="kt_app_sidebar_footer" class="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6">
    <a
      href="https://preview.keenthemes.com/metronic8/vue/docs/#/doc-overview"
      class="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100"
      data-bs-toggle="tooltip"
      data-bs-trigger="hover"
      data-bs-dismiss-="click"
      title="200+ in-house components and 3rd-party plugins"
    >
      <span class="btn-label">Helper</span>
    </a>
  </div>
  <!--end::Footer-->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getAssetPath } from '@/assets/theme/metronic/ts/helpers/assets';

export default defineComponent({
  name: 'ThemeSidebarFooter',
  components: {},
  setup() {
    return {
      getAssetPath
    };
  }
});
</script>
